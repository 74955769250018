<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        When a compound reaches the maximum value in the melting range, what do you expect to see
        visually?
      </p>

      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing" class="mb-n6">
        <v-radio
          v-for="option in options"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'uci51LCPrelab1Q8',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
      options: [
        {
          text: 'The compound begins to boil',
          value: 'boils',
        },
        {
          text: 'The compound begins to melt into the liquid state',
          value: 'beingsMelting',
        },
        {
          text: 'The compound has completely melted into the liquid state',
          value: 'completelyMelts',
        },
        {
          text: 'The compound begins to freeze back into the solid state',
          value: 'freezes',
        },
      ],
    };
  },
};
</script>
